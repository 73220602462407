import { isEqual } from 'lodash-es';
import qs from 'qs';

export default function writeValueToRouterWithoutReactivity<T>({
  writeValueToRouteQuery,
}: {
  writeValueToRouteQuery: (routeQuery: Record<string, unknown>, value: T) => Record<string, unknown>;
}) {
  return (nextValue: T) => {
    const currentRouteQuery = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const nextRouteQuery = writeValueToRouteQuery(currentRouteQuery, nextValue);

    const isRouteQueryChanged = !isEqual(currentRouteQuery, nextRouteQuery);

    if (!isRouteQueryChanged) {
      return;
    }

    const stringifiedQuery = qs
      .stringify(nextRouteQuery, {
        encode: false,
        addQueryPrefix: true,
      })
      .trim();

    window.history.replaceState(window.history.state, '', `${window.location.pathname}${stringifiedQuery}`);
  };
}
