import axios, { AxiosInstance } from 'axios';
import { omit } from 'lodash-es';

import DefaultLogger from '../../../../lib/debug/logger/DefaultLogger';

import ApiRequestOptions from '../../api-request/ApiRequestOptions';
import ApiResponse from '../../api-response/ApiResponse';
import extractErrorMessageFromApiError from '../api-client-errors/extractErrorMessageFromApiError';
import getErrorMessageByStatus from '../api-client-errors/getErrorMessageByStatus';
import ApiClientResponseInterceptor from '../api-client-interceptors/ApiClientResponseInterceptor';

function makeNonApiErrorResponse(error: unknown): ApiResponse {
  return {
    status: 0,
    ok: false,
    json: () => {
      throw new Error(`Non-api error. ${error}`);
    },
    errorMessage: () => Promise.resolve(`Non-api error. ${error}`),
  };
}

export default async function requestViaAxios(
  axiosInstance: AxiosInstance,
  url: string,
  options: ApiRequestOptions & {
    baseUrl?: string;
    responseInterceptors?: ApiClientResponseInterceptor[];
  },
): Promise<ApiResponse> {
  const applyResponseInterceptors = async (response: ApiResponse): Promise<ApiResponse> => {
    if (!options.responseInterceptors) {
      return response;
    }

    let resultResponse = response;

    for (const interceptor of options.responseInterceptors) {
      try {
        resultResponse = await interceptor(response, url, omit(options, 'baseUrl', 'responseInterceptor'));
      } catch (error) {
        DefaultLogger.writeError('Got error during response interceptor', error);
      }
    }

    return resultResponse;
  };

  const makeAxiosOnUploadProgressHandler = () => {
    const { onUploadProgress } = options;

    if (!onUploadProgress) {
      return undefined;
    }

    return (uploadProgressEvent: ProgressEvent) => {
      try {
        onUploadProgress({
          sentBytes: uploadProgressEvent.loaded,
          progress: uploadProgressEvent.loaded / uploadProgressEvent.total,
        });
      } catch (error) {
        // уже и не помню, для чего вообще здесь нужен этот try catch
        DefaultLogger.writeError(error);
      }
    };
  };

  try {
    const response = await axiosInstance.request({
      url: url,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      baseURL: options.baseUrl ?? undefined,
      signal: options.signal,
      method: options.method ?? 'get',
      params: options.params,
      data: options.json ?? options.body,
      onUploadProgress: makeAxiosOnUploadProgressHandler(),
    });

    return applyResponseInterceptors({
      status: response.status,
      ok: true,
      json: <T>() => {
        return Promise.resolve(response.data as T);
      },
    });
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      return applyResponseInterceptors(makeNonApiErrorResponse(error));
    }

    const errorResponseStatus = error.response?.status ?? 0;
    const errorResponseJson = error.response?.data ?? null;

    return applyResponseInterceptors({
      status: errorResponseStatus,
      ok: false,
      json: <T>() => errorResponseJson as T,
      errorMessage: () =>
        Promise.resolve(
          getErrorMessageByStatus(errorResponseStatus) ?? extractErrorMessageFromApiError(errorResponseJson),
        ),
    });
  }
}
