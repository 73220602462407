import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import TrackerMode from '../model/TrackerMode';
import getTrackerMode from './getTrackerMode';

export default async function getIsTrackerInDemoMode(): Promise<boolean> {
  const result = await getTrackerMode();

  if (result.status !== ApiResultStatus.OK) {
    return false;
  }

  return result.mode === TrackerMode.DEMO;
}
