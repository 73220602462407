import { RouteRecordRaw } from 'vue-router';

import CrudAction from '../crud/CrudAction';
import CrudActionType from '../crud/CrudActionType';

const LandingsPage = () => import('../../pages/LandingsPage.vue');
const LandingsMassEditModal = () => import('./landings-mass-edit-modal/LandingsMassEditModal.vue');

export default function getLandingsRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'landings',
      path: '/landings',
      meta: {
        title: 'Landers',
        useIn: ['navigation-tabs'],
      },
      component: LandingsPage,
      props: () => ({
        crudAction: null,
      }),
      children: [
        {
          name: 'edit-many-landings',
          path: 'edit/mass/:ids(\\d+)+',
          component: LandingsMassEditModal,
          props: (route) => ({
            landingIds: route.params.ids,
          }),
        },
      ],
    },
    {
      name: 'create-landing',
      path: '/landings/create',
      component: LandingsPage,
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      name: 'edit-landing',
      path: '/landings/edit/:id',
      component: LandingsPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.EDIT,
          id: route.params.id as string,
        },
      }),
    },
    {
      name: 'clone-landing',
      path: '/landings/clone/:id',
      component: LandingsPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CLONE,
          id: route.params.id as string,
        },
      }),
    },
  ];
}
