import { without } from 'lodash-es';

import ApiRequestMethod from '../api-request/ApiRequestMethod';
import ApiRequestOptions from '../api-request/ApiRequestOptions';
import ApiClient from './ApiClient';
import ApiClientResponseInterceptor from './api-client-interceptors/ApiClientResponseInterceptor';
import createAxiosInstance from './api-client-request/createAxiosInstance';
import requestViaAxios from './api-client-request/requestViaAxios';

export default function createApiClient(clientOptions?: { baseUrl: string }): ApiClient {
  const axiosInstance = createAxiosInstance();

  let responseInterceptors: ApiClientResponseInterceptor[] = [];

  const request = (url: string, options: ApiRequestOptions) =>
    requestViaAxios(axiosInstance, url, {
      ...options,
      baseUrl: clientOptions?.baseUrl,
      responseInterceptors,
    });

  return {
    request: (url, options) => {
      return request(url, options ?? {});
    },
    get: (url, options) => {
      return request(url, {
        method: options?.method ?? ApiRequestMethod.GET,
        ...options,
      });
    },
    post: (url, options) => {
      return request(url, {
        method: options?.method ?? ApiRequestMethod.POST,
        ...options,
      });
    },
    put: (url, options) => {
      return request(url, {
        method: options?.method ?? ApiRequestMethod.PUT,
        ...options,
      });
    },
    patch: (url, options) => {
      return request(url, {
        method: options?.method ?? ApiRequestMethod.PATCH,
        ...options,
      });
    },
    delete: (url, options) => {
      return request(url, {
        method: options?.method ?? ApiRequestMethod.DELETE,
        ...options,
      });
    },
    getBaseUrl: () => clientOptions?.baseUrl ?? null,
    useResponseInterceptor: (interceptor) => {
      responseInterceptors = [...responseInterceptors, interceptor];

      return () => {
        responseInterceptors = without(responseInterceptors, interceptor);
      };
    },
  };
}
