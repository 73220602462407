import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import TrackerMode from '../model/TrackerMode';

export default async function getTrackerMode(): Promise<
  | {
      status: ApiResultStatus.ERROR;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.OK;
      mode: TrackerMode;
    }
> {
  const client = getApiClient();

  const response = await client.get('/api/mode');

  if (!response.ok) {
    return {
      status: ApiResultStatus.ERROR,
      errorMessage: await response.errorMessage(),
    };
  }

  const json = await response.json<{
    mode: string;
  }>();

  return {
    status: ApiResultStatus.OK,
    mode: json.mode === 'demo' ? TrackerMode.DEMO : TrackerMode.NORMAL,
  };
}
