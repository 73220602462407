function getRandomTimeout() {
  const minTimeout = 1000;
  const maxTimeout = 2000;
  return Math.random() * maxTimeout + minTimeout;
}
export default function sleep(timeout: number | 'random' = 500) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout === 'random' ? getRandomTimeout() : timeout);
  });
}
