function isResponseOriginalDataObject(responseOriginalData: unknown): responseOriginalData is Record<string, unknown> {
  return !!responseOriginalData && typeof responseOriginalData === 'object' && !Array.isArray(responseOriginalData);
}

export default function extractErrorMessageFromLicenseExpiringErrorResponseOriginalData(
  responseOriginalData: unknown,
): string | null {
  if (
    !isResponseOriginalDataObject(responseOriginalData) ||
    !('error' in responseOriginalData) ||
    typeof responseOriginalData.error !== 'string'
  ) {
    return null;
  }

  return responseOriginalData.error;
}
