import { toRefs } from '@vueuse/core';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { z } from 'zod';

import useCache from '../../../lib/local-storage/useCache';
import MayBePromise from '../../../lib/types/MayBePromise';

import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import getIsHideProfit from '../../users/api/getIsHideProfit';
import UserRole from '../../users/models/UserRole';
import getCurrentUserIdAndRoleDeduplicated from '../api/getCurrentUserIdAndRoleDeduplicated';
import logOut from '../api/logOut';
import signIn from '../api/signIn';

export type CurrentUser = {
  readonly id: string;
  readonly username: string;
  readonly role: UserRole;
  readonly isHideProfit: boolean;
};

type State = {
  readonly user: CurrentUser | null;
};

const defaultState = (): State => ({
  user: null,
});

const LOCAL_STORAGE_KEY = 'currentUser';

const SCHEMA_VERSION = '15.08.24';

const stateSchema = z.object({
  user: z
    .object({
      id: z.string(),
      username: z.string(),
      role: z.nativeEnum(UserRole),
      isHideProfit: z.boolean(),
    })
    .nullable(),
});

export default defineStore('currentUser', () => {
  const { getValue: readState, setValue: writeState } = useCache(LOCAL_STORAGE_KEY, SCHEMA_VERSION, stateSchema);

  const state = ref<State>(readState() || defaultState());

  const updateState = (updater: (currentState: State) => State) => {
    const nextState = updater(state.value);

    state.value = {
      ...nextState,
    };

    writeState(nextState);
  };

  const isStateLoading = ref(false);

  const loadState = async (
    options: {
      onOk?: () => MayBePromise<void>;
      onError?: (errorMessage: string) => MayBePromise<void>;
    } = {},
  ) => {
    if (isStateLoading.value) {
      return;
    }

    isStateLoading.value = true;

    const userIdAndRoleGettingResult = await getCurrentUserIdAndRoleDeduplicated();

    if (userIdAndRoleGettingResult.status !== ApiResultStatus.OK) {
      isStateLoading.value = false;
      await options.onError?.(userIdAndRoleGettingResult.errorMessage);
      return;
    }

    const { id, role } = userIdAndRoleGettingResult;

    const hideProfitSettingsGettingResult = await getIsHideProfit(id);

    if (hideProfitSettingsGettingResult.status !== ApiResultStatus.OK) {
      isStateLoading.value = false;
      await options.onError?.(hideProfitSettingsGettingResult.errorMessage);
      return;
    }

    const { isHideProfit } = hideProfitSettingsGettingResult;

    updateState((currentState) => ({
      user: {
        id,
        role,
        username: currentState.user?.username ?? '%username%',
        isHideProfit,
      },
    }));

    isStateLoading.value = false;

    await options.onOk?.();
  };

  const init = async () => {
    await loadState();
  };

  const updateCurrentUserAfterAuthentication = async (
    username: string,
    options?: {
      onOk?: () => MayBePromise<void>;
      onError?: (errorMessage: string) => MayBePromise<void>;
    },
  ) => {
    await loadState(options);

    updateState((currentState) => {
      if (!currentState.user) {
        return currentState;
      }

      return {
        user: {
          ...currentState.user,
          username,
        },
      };
    });
  };

  const isSigningIn = ref(false);

  const doSignIn = async (
    credentials: {
      username: string;
      password: string;
    },
    options: {
      onOk?: () => MayBePromise<void>;
      onError?: (errorMessage: string) => MayBePromise<void>;
    } = {},
  ) => {
    if (isSigningIn.value) {
      return;
    }

    isSigningIn.value = true;

    try {
      const result = await signIn(credentials.username, credentials.password);

      if (result.status !== ApiResultStatus.OK) {
        await options.onError?.(result.errorMessage);
        isSigningIn.value = false;
        return;
      }

      await updateCurrentUserAfterAuthentication(credentials.username, options);

      isSigningIn.value = false;
    } catch {
      isSigningIn.value = false;
    }
  };

  const isLoggingOut = ref(false);

  const doLogOut = async () => {
    if (isLoggingOut.value) {
      return;
    }

    isLoggingOut.value = true;

    await logOut();

    updateState(() => ({
      user: null,
    }));

    isLoggingOut.value = false;
  };

  const isHideProfit = computed(() => {
    return state.value.user?.isHideProfit ?? false;
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const __deprecated__updateCurrentUser = (nextUser: { id: string; username: string; role: UserRole }) => {
    if (!state.value.user) {
      state.value = {
        user: {
          ...nextUser,
          isHideProfit: false,
        },
      };
      return;
    }

    state.value = {
      user: {
        ...state.value.user,
        ...nextUser,
      },
    };
  };

  return {
    ...toRefs(state),
    isHideProfit,
    init,
    logOut: doLogOut,
    isLoggingOut,
    signIn: doSignIn,
    isSigningIn,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __deprecated__updateCurrentUser,
  };
});
