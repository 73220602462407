import DOMPurify from 'dompurify';

export default function extractTextFromHtmlString(value: string): string {
  return DOMPurify.sanitize(value, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    USE_PROFILES: {
      html: false,
      svg: false,
      mathMl: false,
      svgFilters: false,
    },
  });
}
