import IsDebugCheckerInterface from '../is-debug/IsDebugCheckerInterface';

function normalizeGroupLines<T>(lines: (T | T[])[] | T): T[][] {
  if (Array.isArray(lines)) {
    if (lines.length === 0) {
      return [[] as T[]];
    }
    return lines.map((line): T[] => (Array.isArray(line) ? line : [line]));
  }
  return [[lines]];
}

export default class Logger {
  public constructor(isDebugChecker: IsDebugCheckerInterface) {
    this.isDebugCheckers = [isDebugChecker];
  }

  public setIsDebugChecker(isDebugChecker: IsDebugCheckerInterface): void {
    this.isDebugCheckers = [isDebugChecker];
  }

  public appendIsDebugChecker(isDebugChecker: IsDebugCheckerInterface): void {
    this.isDebugCheckers = [...this.isDebugCheckers, isDebugChecker];
  }

  public writeMessage(...data: unknown[]): void {
    this.write('log', data);
  }

  public writeWarning(...data: unknown[]): void {
    this.write('warn', data);
  }

  public writeError(...data: unknown[]): void {
    this.write('error', data);
  }

  public trace(): void {
    if (!this.isDebug) {
      return;
    }
    // eslint-disable-next-line no-console
    console.trace();
  }

  public writeGroup<T>(
    {
      name,
      lines,
    }: {
      name: string;
      lines: (T | T[])[] | T;
    },
    loggerType: 'error' | 'message' | 'warning' = 'message',
  ) {
    if (!this.isDebug) {
      return;
    }
    // eslint-disable-next-line no-console
    console.groupCollapsed(name);
    for (const line of normalizeGroupLines(lines)) {
      if (loggerType === 'error') {
        this.writeError(...line);
      } else if (loggerType === 'warning') {
        this.writeWarning(...line);
      } else {
        this.writeMessage(...line);
      }
    }
    // eslint-disable-next-line no-console
    console.groupEnd();
  }

  protected get isDebug() {
    return this.isDebugCheckers.some((checker) => checker.isDebug());
  }

  private write(method: 'error' | 'log' | 'warn', data: unknown[]): void {
    if (!this.isDebug) {
      return;
    }
    // eslint-disable-next-line no-console
    console[method](...data);
  }

  private isDebugCheckers: IsDebugCheckerInterface[] = [];
}
