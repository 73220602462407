enum MonitorForDevelopersRouteName {
  MAIN = 'main',
  BUILD_INFO = 'build-info',
  DEBUG_SETTINGS = 'debug-settings',
  SEED = 'seed',
  CLICKER = 'clicker',
  MESSAGES = 'messages',
}

export default MonitorForDevelopersRouteName;
