import { RouteRecordRaw } from 'vue-router';

import CrudAction from '../crud/CrudAction';
import CrudActionType from '../crud/CrudActionType';

const AffiliateNetworksPage = () => import('../../pages/AffiliateNetworksPage.vue');

export default function getAffiliateNetworksRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'affiliate-networks',
      path: '/affiliate-networks',
      meta: {
        title: 'Affiliate Networks',
        tabTitle: 'Aff. networks',
        useIn: ['navigation-tabs'],
      },
      component: AffiliateNetworksPage,
      props: () => ({
        crudAction: null,
      }),
    },
    {
      name: 'create-affiliate-network',
      path: '/affiliate-networks/create',
      component: AffiliateNetworksPage,
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      name: 'edit-affiliate-network',
      path: '/affiliate-networks/edit/:id',
      component: AffiliateNetworksPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.EDIT,
          id: route.params.id as string,
        },
      }),
    },
    {
      name: 'clone-affiliate-network',
      path: '/affiliate-networks/clone/:id',
      component: AffiliateNetworksPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CLONE,
          id: route.params.id as string,
        },
      }),
    },
  ];
}
