import { computed } from 'vue';

import makeStoreForLoadingDataAndCachingIt from '../../lib/cache/makeStoreForLoadingDataAndCachingIt';

import ApiResultStatus from '../../shared/api/api-result/ApiResultStatus';
import getAuthSecretToken from './api/getAuthSecretToken';

const useAuthSecretTokenStore = makeStoreForLoadingDataAndCachingIt({
  storeKey: 'auth-secret-token',
  loadData: async () => {
    const result = await getAuthSecretToken();

    if (result.status !== ApiResultStatus.OK) {
      return null;
    }

    return result.authSecretToken;
  },
  getInitialData: () => null,
  delayBeforeLoadingActualData: 5000,
});

export default function useAuthSecretToken() {
  const store = useAuthSecretTokenStore();

  const authSecretToken = computed(() => store.loadedData);

  const isAuthSecretTokenLoading = computed(() => store.isDataVisuallyLoading);

  const isCachedAuthSecretToken = computed(() => !store.isDataLoaded);

  const loadAuthSecretToken = async () => {
    await store.loadData();
  };

  void loadAuthSecretToken();

  return {
    authSecretToken,
    isAuthSecretTokenLoading,
    loadAuthSecretToken,
    isCachedAuthSecretToken,
  };
}
