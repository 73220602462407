import { RouteRecordRaw } from 'vue-router';

import CrudAction from '../crud/CrudAction';
import CrudActionType from '../crud/CrudActionType';

const OffersPage = () => import('../../pages/OffersPage.vue');
const OffersMassEditModal = () => import('./offers-mass-edit-modal/OffersMassEditModal.vue');

export default function getOffersRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'offers',
      path: '/offers',
      meta: {
        title: 'Offers',
        useIn: ['navigation-tabs'],
      },
      component: OffersPage,
      props: () => ({
        crudAction: null,
      }),
      children: [
        {
          name: 'edit-many-offers',
          path: 'edit/mass/:ids(\\d+)+',
          component: OffersMassEditModal,
          props: (route) => ({
            offerIds: route.params.ids,
          }),
        },
      ],
    },
    {
      name: 'create-offer',
      path: '/offers/create',
      component: OffersPage,
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      name: 'edit-offer',
      path: '/offers/edit/:id',
      component: OffersPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.EDIT,
          id: route.params.id as string,
        },
      }),
    },
    {
      name: 'clone-offer',
      path: '/offers/clone/:id',
      component: OffersPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CLONE,
          id: route.params.id as string,
        },
      }),
    },
  ];
}
