import { createPinia } from 'pinia';
import { createApp } from 'vue';

import createTanstackQuery from './lib/tanstack-query/createTanstackQuery';
import createToast from './lib/toast-notification/createToast';

import App from './app/App.vue';
import createRouter from './router/createRouter';

const initApp = () => {
  const app = createApp(App);

  const ROUTER_PREFIX = 'panel';

  if (!window.location.pathname.startsWith(`/${ROUTER_PREFIX}/`)) {
    window.history.replaceState(
      window.history.state,
      '',
      `/${ROUTER_PREFIX}${window.location.search}${window.location.hash}`,
    );
  }

  app.use(createRouter(ROUTER_PREFIX));

  app.use(createPinia());

  app.use(...createToast());

  app.use(...createTanstackQuery());

  app.mount('.main');
};

initApp();
