import { defineStore } from 'pinia';

type State = {
  isEnabled: boolean;
  errorMessage?: string;
};
export default defineStore('monitorObserverStore', {
  state: (): State => ({
    isEnabled: true,
    errorMessage: 'Monitor and updating are not working. Please contact our support.',
  }),
  actions: {
    setIsObserverEnabled(isObserverEnabled: boolean) {
      this.isEnabled = isObserverEnabled;
    },
  },
});
