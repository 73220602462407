import UserRole from '../../../../users/models/UserRole';
import UserRoleDto from '../UserRoleDto';

const userRoleDtoToUserRoleMapping: Record<UserRoleDto, UserRole> = {
  admin: UserRole.ADMIN,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  super_admin: UserRole.SUPER_ADMIN,
  user: UserRole.USER,
};

export default function transformUserRoleFromDto(userRoleDto: UserRoleDto): UserRole {
  return userRoleDtoToUserRoleMapping[userRoleDto];
}
