import { until, useStorage } from '@vueuse/core';

import { computed, ref } from 'vue';
import { z } from 'zod';

import DefaultLogger from '../debug/logger/DefaultLogger';

const extractMessageFromError = (error: unknown): string => {
  if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }

  return `${error}`;
};

export default function usePersistedQuery<T>({
  queryKey,
  getInitialValue,
  parseValue,
  queryFn,
  staleTime,
}: {
  queryKey: string;
  getInitialValue: () => T;
  parseValue: (value: unknown) => T;
  queryFn: () => Promise<T>;
  staleTime: number;
}) {
  const storedStateSchema = z.object({
    value: z.any(),
    updatedAt: z.number(),
  });

  type StoredState = {
    value: T;
    updatedAt: number;
    isCached: boolean;
  };

  const getInitialStoredState = (): StoredState => ({
    value: getInitialValue(),
    updatedAt: 0,
    isCached: false,
  });

  const deserializeStoredState = (rawState: string): StoredState => {
    if (!rawState) {
      return getInitialStoredState();
    }
    try {
      const storedState = storedStateSchema.parse(JSON.parse(rawState));

      return {
        value: parseValue(storedState.value),
        updatedAt: storedState.updatedAt,
        isCached: true,
      };
    } catch (error) {
      DefaultLogger.writeError(error);
      return getInitialStoredState();
    }
  };

  const serializeStoredState = (storedState: StoredState): string => {
    return JSON.stringify(storedState);
  };

  const storedState = useStorage<StoredState>(queryKey, getInitialStoredState(), undefined, {
    serializer: {
      read: deserializeStoredState,
      write: serializeStoredState,
    },
  });

  const updateValue = (value: T) => {
    storedState.value = {
      ...storedState.value,
      value,
      isCached: false,
    };
  };

  const resetValue = () => {
    storedState.value = {
      ...storedState.value,
      value: getInitialValue(),
      isCached: false,
    };
  };

  const isFetching = ref(false);
  const fetchingError = ref<string | null>(null);

  const onStartFetching = () => {
    fetchingError.value = null;
    isFetching.value = true;
  };

  const onFetchedWithSuccess = (result: T) => {
    updateValue(result);
    fetchingError.value = null;
    isFetching.value = false;
  };

  const onFetchedWithError = (error: unknown) => {
    resetValue();
    fetchingError.value = extractMessageFromError(error);
    isFetching.value = false;
  };

  const refetch = async () => {
    if (isFetching.value) {
      await until(isFetching.value).not.toBeTruthy();
      return;
    }

    onStartFetching();

    try {
      const result = await queryFn();
      onFetchedWithSuccess(result);
    } catch (error) {
      onFetchedWithError(error);
    }
  };

  const isUpdatedRecently = () => {
    const shouldBeUpdatedAfter = staleTime + storedState.value.updatedAt;

    return Date.now() < shouldBeUpdatedAfter;
  };

  const fetchInitially = async () => {
    if (isUpdatedRecently()) {
      return;
    }

    await refetch();
  };

  void fetchInitially();

  return {
    value: computed(() => storedState.value.value),
    isCached: computed(() => storedState.value.isCached),
    isFetching: computed(() => isFetching.value),
    fetchingError: computed(() => fetchingError.value),
    refetch,
  };
}
