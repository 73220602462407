import DebugSettings from './DebugSettings';

export default function readDebugSettingsField<T extends keyof DebugSettings>(settingsFieldKey: T) {
  const readValue = localStorage.getItem(`debugSettings.${settingsFieldKey}`);

  if (!readValue) {
    return null;
  }

  return JSON.parse(readValue) as DebugSettings[T];
}
