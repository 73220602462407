enum ApiRequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  HEAD = 'head',
  DELETE = 'delete',
}

export default ApiRequestMethod;
