import { useRouter } from 'vue-router';

import DefaultLogger from '../../lib/debug/logger/DefaultLogger';

export default function logRouterNavigation() {
  const router = useRouter();

  router.beforeEach((to, from) => {
    DefaultLogger.writeGroup({
      name: 'Route navigation',
      lines: [
        ['from', from],
        ['to', to],
      ],
    });
  });
}
