import DefaultLogger from '../../lib/debug/logger/DefaultLogger';

import useDebugSettingsField from '../monitor/developer/monitor-debug-settings/useDebugSettingsField';

export default function applyDebugCheckerBasedOnMonitorDebugSettingsToDefaultLogger() {
  const { value: isEnableLogging } = useDebugSettingsField('isEnableLogging');

  DefaultLogger.appendIsDebugChecker({
    isDebug: () => isEnableLogging.value,
  });
}
