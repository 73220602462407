import { RouteRecordRaw } from 'vue-router';

import SettingsRouteName from './SettingsRouteName';

const PublicApiSettings = () => import('./public-api-settings/PublicApiSettings.vue');
const ClickApiSettings = () => import('./click-api-settings/ClickApiSettings.vue');
const ColumnsSettings = () => import('./columns-settings/ColumnsSettings.vue');
const ImportExportSettings = () => import('./import-export-settings/ImportExportSettings.vue');
const LandingPageProtectSettings = () => import('./landing-page-protect-settings/LandingPageProtectSettings.vue');
const IntegrationSettings = () => import('./integration-settings/IntegrationSettings.vue');
const MainStatsSettings = () => import('./main-stats-settings/MainStatsSettings.vue');
const PostbackProcessingSettings = () => import('./postback-processing-settings/PostbackProcessingSettings.vue');
const TrackingLinksSettings = () => import('./tracking-links-settings/TrackingLinksSettings.vue');
const UrlCustomizationSettings = () => import('./url-customization-settings/UrlCustomizationSettings.vue');
const UserSettings = () => import('./user-settings/UserSettings.vue');
const AutoClearingsSettings = () => import('./auto-clearings-settings/AutoClearingsSettings.vue');
const FeedbackSettings = () => import('./feedback-settings/FeedbackSettings.vue');

const SettingsPage = () => import('../../pages/settings/SettingsPage.vue');

export default function getSettingsRoutes(): RouteRecordRaw[] {
  return [
    {
      name: SettingsRouteName.SETTINGS,
      path: '/settings',
      meta: {
        title: 'Settings',
        useIn: ['menu'],
      },
      component: SettingsPage,
      redirect: {
        name: SettingsRouteName.TRACKING_LINKS_SETTINGS,
      },
      children: [
        {
          name: SettingsRouteName.TRACKING_LINKS_SETTINGS,
          path: 'tracking-links',
          meta: {
            title: 'Tracking links - Settings',
          },
          component: TrackingLinksSettings,
        },
        {
          name: SettingsRouteName.AUTO_CLEARING,
          path: 'auto-clearing',
          meta: {
            title: 'Auto clearings clicks',
          },
          component: AutoClearingsSettings,
        },
        {
          name: SettingsRouteName.USER_SETTINGS,
          path: 'user',
          meta: {
            title: 'User - Settings',
          },
          component: UserSettings,
        },
        {
          name: SettingsRouteName.STATS_SETTINGS,
          path: 'stats',
          meta: {
            title: 'Stats - Settings',
          },
          component: MainStatsSettings,
        },
        {
          name: SettingsRouteName.COLUMNS_SETTINGS,
          path: 'columns',
          meta: {
            title: 'Columns - Settings',
          },
          component: ColumnsSettings,
        },
        {
          name: SettingsRouteName.PUBLIC_API_SETTINGS,
          path: 'public-api',
          meta: {
            title: 'Public API - Settings',
          },
          component: PublicApiSettings,
        },
        {
          name: SettingsRouteName.CLICK_API_SETTINGS,
          path: 'click-api',
          meta: {
            title: 'Click API - Settings',
          },
          component: ClickApiSettings,
        },
        {
          name: SettingsRouteName.LANDING_PAGE_PROTECT_SETTINGS,
          path: 'lp-protect',
          meta: {
            title: 'LP protect - Settings',
          },
          component: LandingPageProtectSettings,
        },
        {
          name: SettingsRouteName.INTEGRATION,
          path: 'integration',
          meta: {
            title: 'Integration - Settings',
          },
          component: IntegrationSettings,
        },
        {
          name: SettingsRouteName.POSTBACK_PROCESSING_SETTINGS,
          path: 'postback-processing',
          meta: {
            title: 'Postback processing - Settings',
          },
          component: PostbackProcessingSettings,
        },
        {
          name: SettingsRouteName.IMPORT_EXPORT_SETTINGS,
          path: 'import-export',
          meta: {
            title: 'Import/Export - Settings',
          },
          component: ImportExportSettings,
        },
        {
          name: SettingsRouteName.URL_CUSTOMIZATION_SETTINGS,
          path: 'url-customization',
          meta: {
            title: 'URL customization - Settings',
          },
          component: UrlCustomizationSettings,
        },
        {
          name: SettingsRouteName.FEEDBACK_SETTINGS,
          path: 'feedback',
          meta: {
            title: 'Feedback - Settings',
          },
          component: FeedbackSettings,
        },
      ],
    },
  ];
}
