import { useRouter } from 'vue-router';

import useUpdateAvailabilityStore from '../update-availability/useUpdateAvailabilityStore';

export default function useUpdatePageRouterGuard() {
  const router = useRouter();

  const store = useUpdateAvailabilityStore();

  router.beforeEach(async (to, _, next) => {
    if (to.name !== 'update') {
      next();
      return;
    }

    await store.recheckForUpdates();

    if (store.isUpdateAvailable) {
      next();
      return;
    }

    next({
      name: 'campaigns',
    });
  });
}
