import { RouteRecordRaw } from 'vue-router';

const TrendsPage = () => import('../../pages/TrendsPage.vue');

export default function getTrendsRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/trends',
      name: 'trends',
      meta: {
        title: 'Trends',
        useIn: ['navigation-tabs'],
      },
      component: TrendsPage,
    },
  ];
}
