import { NavigationGuardWithThis, useRouter } from 'vue-router';

import MayBePromise from '../types/MayBePromise';

export default function onBeforeEachOnce(
  guard: NavigationGuardWithThis<undefined>,
  onBeforePassing?: () => MayBePromise<void>,
  onPassed?: () => MayBePromise<void>,
) {
  const router = useRouter();

  const stopBeforeEachHook = router.beforeEach(async (to, from, next) => {
    void onBeforePassing?.();

    await guard.call(undefined, to, from, next);

    stopBeforeEachHook();

    void onPassed?.();
  });
}
