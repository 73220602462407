import { LocationQueryValueRaw, useRoute, useRouter } from 'vue-router';

import RouteName from '../../../router/RouteName';

export default function useRedirectToAuthenticationPage() {
  const route = useRoute();

  const router = useRouter();

  return async ({
    returnTo = null,
    method = 'push',
  }: {
    returnTo?: LocationQueryValueRaw | LocationQueryValueRaw[];
    method?: 'push' | 'replace';
  } = {}) => {
    if (route.name === RouteName.AUTHENTICATION) {
      return;
    }

    const query: Record<string, LocationQueryValueRaw | LocationQueryValueRaw[]> = {};

    if (returnTo) {
      query.returnTo = returnTo;
    }

    await router[method]({
      name: RouteName.AUTHENTICATION,
      query,
    });
  };
}
