function isResponseOriginalDataObject(responseOriginalData: unknown): responseOriginalData is Record<string, unknown> {
  return !!responseOriginalData && typeof responseOriginalData === 'object' && !Array.isArray(responseOriginalData);
}

export default function extractTrackerIdFromLicenseExpiringErrorResponseOriginalData(
  responseOriginalData: unknown,
): string | null {
  if (
    !isResponseOriginalDataObject(responseOriginalData) ||
    !('trackerId' in responseOriginalData) ||
    typeof responseOriginalData.trackerId !== 'string'
  ) {
    return null;
  }

  return responseOriginalData.trackerId;
}
