import { RouteRecordRaw } from 'vue-router';

import CrudAction from '../crud/CrudAction';
import CrudActionType from '../crud/CrudActionType';

const TrafficSourcesPage = () => import('../../pages/TrafficSourcesPage.vue');

export default function getTrafficSourcesRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'traffic-sources',
      path: '/traffic-sources',
      meta: {
        title: 'Traffic sources',
        useIn: ['navigation-tabs'],
      },
      component: TrafficSourcesPage,
      props: () => ({
        crudAction: null,
      }),
    },
    {
      name: 'create-traffic-source',
      path: '/traffic-sources/create',
      component: TrafficSourcesPage,
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      name: 'edit-traffic-source',
      path: '/traffic-sources/edit/:id',
      component: TrafficSourcesPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.EDIT,
          id: route.params.id as string,
        },
      }),
    },
    {
      name: 'clone-traffic-source',
      path: '/traffic-sources/clone/:id',
      component: TrafficSourcesPage,
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CLONE,
          id: route.params.id as string,
        },
      }),
    },
  ];
}
