import { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const CampaignsPage = () => import('../../pages/CampaignsPage.vue');
const CampaignFormPage = () => import('../../pages/CampaignFormPage.vue');
const CampaignsMassEditModal = () => import('./campaigns-mass-edit-modal/CampaignsMassEditModal.vue');

export default function getCampaignsRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/',
      name: 'campaigns',
      meta: {
        title: 'Campaigns',
        useIn: ['navigation-tabs'],
      },
      component: CampaignsPage,
      alias: '/campaigns',
      children: [
        {
          name: 'edit-many-campaigns',
          path: 'edit/mass/:ids(\\d+)+',
          component: CampaignsMassEditModal,
          props: (route) => ({
            campaignIds: route.params.ids,
          }),
        },
      ],
    },
    {
      path: '/campaigns/create',
      name: 'create-campaign',
      component: CampaignFormPage,
      props: {
        action: {
          type: CrudActionType.CREATE,
        },
      },
      meta: {
        title: 'Create campaign',
      },
    },
    {
      path: '/campaigns/edit/:id',
      name: 'edit-campaign',
      component: CampaignFormPage,
      props: (route) => ({
        action: {
          type: CrudActionType.EDIT,
          id: route.params.id,
        },
      }),
      meta: {
        title: 'Edit campaign',
      },
    },
    {
      path: '/campaigns/clone/:id',
      name: 'clone-campaign',
      component: CampaignFormPage,
      props: (route) => ({
        action: {
          type: CrudActionType.CLONE,
          id: route.params.id,
        },
      }),
      meta: {
        title: 'Clone campaign',
      },
    },
  ];
}
