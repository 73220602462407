import dayjs from 'dayjs';
import durationDayjsPlugin from 'dayjs/plugin/duration';

import DurationUnitType from './DurationUnitType';

dayjs.extend(durationDayjsPlugin);

export default function getDurationInSeconds(time: number, unit: DurationUnitType = DurationUnitType.SECOND) {
  return dayjs.duration(time, unit).asSeconds();
}
