import ApiClient from './ApiClient';
import createApiClient from './createApiClient';

const API_CLIENTS = {
  default: createApiClient({
    baseUrl: '',
  }),
};

export default function getApiClient(): ApiClient {
  return API_CLIENTS.default;
}
