import { RouteRecordRaw } from 'vue-router';

import MonitorForDevelopersRouteName from './MonitorForDevelopersRouteName';

const MonitorMessages = () => import('./monitor-messages/MonitorMessages.vue');
const MonitorBuildInfo = () => import('./monitor-build-info/MonitorBuildInfo.vue');
const MonitorClicker = () => import('./monitor-clicker/MonitorClicker.vue');
const MonitorDebugSettings = () => import('./monitor-debug-settings/MonitorDebugSettings.vue');
const MonitorSeed = () => import('./monitor-seed/MonitorSeed.vue');

const MonitorPageForDevelopers = () => import('../../../pages/MonitorPageForDevelopers.vue');

export default function getMonitorForDevelopersRoutes(): RouteRecordRaw[] {
  return [
    {
      name: MonitorForDevelopersRouteName.MAIN,
      path: '/monitor/developer',
      meta: {
        title: 'Monitor for developers',
      },
      component: MonitorPageForDevelopers,
      redirect: {
        name: MonitorForDevelopersRouteName.BUILD_INFO,
      },
      children: [
        {
          name: MonitorForDevelopersRouteName.BUILD_INFO,
          path: 'build-info',
          meta: {
            title: 'Build info - Monitor for developers',
          },
          component: MonitorBuildInfo,
        },
        {
          name: MonitorForDevelopersRouteName.DEBUG_SETTINGS,
          path: 'debug-settings',
          meta: {
            title: 'Debug settings - Monitor for developers',
          },
          component: MonitorDebugSettings,
        },
        {
          name: MonitorForDevelopersRouteName.SEED,
          path: 'seed',
          meta: {
            title: 'Seed - Monitor for developers',
          },
          component: MonitorSeed,
        },
        {
          name: MonitorForDevelopersRouteName.CLICKER,
          path: 'clicker',
          meta: {
            title: 'Clicker - Monitor for developers',
          },
          component: MonitorClicker,
        },
        {
          name: MonitorForDevelopersRouteName.MESSAGES,
          path: 'messages',
          meta: {
            title: 'Messages - Monitor for developers',
          },
          component: MonitorMessages,
        },
      ],
    },
  ];
}
