const GATEWAY_TIMEOUT_CODE = 504;
const DEMO_UNAVAILABLE_HTTP_CODE = 410;

export default function getErrorMessageByStatus(status: number): string | null {
  if (status === GATEWAY_TIMEOUT_CODE) {
    return 'Gateway timeout. Simplify the request or try again later.';
  }
  if (status === DEMO_UNAVAILABLE_HTTP_CODE) {
    return `This feature is unavailable in the demo version. However, you can access our 14-day free trial with all features available. Simply register at binom.org and contact our support team (https://cp.binom.org/page/support) for assistance, or write to us on Telegram (https://t.me/BinomSupportBot).`;
  }
  return null;
}
