import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import extractErrorMessageFromLicenseExpiringErrorResponseOriginalData from '../../license/expiring/extractErrorMessageFromLicenseExpiringErrorResponseOriginalData';
import extractTrackerIdFromLicenseExpiringErrorResponseOriginalData from '../../license/expiring/extractTrackerIdFromLicenseExpiringErrorResponseOriginalData';
import UserRole from '../../users/models/UserRole';
import GetCurrentUserIdAndRoleResponseDto from './dto/GetCurrentUserIdAndRoleResponseDto';
import transformUserRoleFromDto from './dto/transformers/transformUserRoleFromDto';

const FORBIDDEN_HTTP_CODE = 403;
const LICENSE_EXPIRED_HTTP_CODE = 402;

export default async function getCurrentUserIdAndRole(): Promise<
  | {
      status: ApiResultStatus.ACCESS_DENIED;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.ERROR;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.LICENSE_EXPIRED;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.OK;
      id: string;
      role: UserRole;
    }
> {
  const client = getApiClient();

  const response = await client.get('/api/user/role');

  if (response.ok) {
    const json = await response.json<GetCurrentUserIdAndRoleResponseDto>();

    return {
      status: ApiResultStatus.OK,
      id: String(json.id),
      role: transformUserRoleFromDto(json.role),
    };
  }

  const json = await response.json<{
    message?: string;
  }>();

  if (response.status === FORBIDDEN_HTTP_CODE || json.message?.includes('AUTHENTICATION_ERROR')) {
    return {
      status: ApiResultStatus.ACCESS_DENIED,
      errorMessage: await response.errorMessage(),
    };
  }

  if (response.status === LICENSE_EXPIRED_HTTP_CODE) {
    const errorMessage = extractErrorMessageFromLicenseExpiringErrorResponseOriginalData(json) ?? '';
    const trackerId = extractTrackerIdFromLicenseExpiringErrorResponseOriginalData(json);

    return {
      status: ApiResultStatus.LICENSE_EXPIRED,
      errorMessage: `${errorMessage}\n\nTracker id: ${trackerId}`,
    };
  }

  return {
    status: ApiResultStatus.ERROR,
    errorMessage: await response.errorMessage(),
  };
}
