import DataTableColorizationMode from '../../../data-table/data-table-colorization/models/DataTableColorizationMode';
import DebugSettings from './DebugSettings';

export default function getInitialDebugSettings(): DebugSettings {
  return {
    isEnableLogging: false,
    colorizationMode: DataTableColorizationMode.COLORED_ROWS,
    isWithVerticalBorders: false,
    isSyncSortingAndFiltersWithRouter: true,
    isShowMonitorForDevelopersInMenu: false,
  };
}
