import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';

export default async function getAuthSecretToken(): Promise<
  | {
      status: ApiResultStatus.ERROR;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.OK;
      authSecretToken: string;
    }
> {
  const client = getApiClient();

  const response = await client.get('/api/auth/secret');

  if (!response.ok) {
    return {
      status: ApiResultStatus.ERROR,
      errorMessage: await response.errorMessage(),
    };
  }

  const json = await response.json<{
    secret: string;
  }>();

  return {
    status: ApiResultStatus.OK,
    authSecretToken: json.secret,
  };
}
