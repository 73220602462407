import { RouteRecordRaw } from 'vue-router';

import RouteName from '../../router/RouteName';

const AuthenticationPage = () => import('../../pages/AuthenticationPage.vue');

export default function getAuthenticationRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/login',
      name: RouteName.AUTHENTICATION,
      meta: {
        title: 'Login',
      },
      component: AuthenticationPage,
    },
  ];
}
