import { defineStore } from 'pinia';

interface State {
  error: string | null;
}

export default defineStore('authenticationModalError', {
  state: (): State => ({
    error: null,
  }),
  actions: {
    setError(error: string | null) {
      this.error = error;
    },
    clearError() {
      this.setError(null);
    },
    getErrorAndClear() {
      const { error } = this;

      this.clearError();

      return error;
    },
  },
});
