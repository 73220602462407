import { RouteRecordRaw } from 'vue-router';

import CrudAction from '../crud/CrudAction';
import CrudActionType from '../crud/CrudActionType';

const RotationsPage = () => import('../../pages/RotationsPage.vue');
const RotationFormPage = () => import('../../pages/RotationFormPage.vue');

export default function getRotationsRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/rotations',
      name: 'rotations',
      meta: {
        title: 'Rotations',
        useIn: ['navigation-tabs'],
      },
      component: RotationsPage,
    },
    {
      path: '/rotations/create',
      name: 'create-rotation',
      component: RotationFormPage,
      props: (): {
        action: CrudAction;
      } => ({
        action: {
          type: CrudActionType.CREATE,
        },
      }),
      meta: {
        title: 'Create rotation',
      },
    },
    {
      path: '/rotations/edit/:id',
      name: 'edit-rotation',
      component: RotationFormPage,
      props: (
        route,
      ): {
        action: CrudAction;
      } => ({
        action: {
          type: CrudActionType.EDIT,
          id: route.params.id as string,
        },
      }),
      meta: {
        title: 'Edit rotation',
      },
    },
    {
      path: '/rotations/clone/:id',
      name: 'clone-rotation',
      component: RotationFormPage,
      props: (
        route,
      ): {
        action: CrudAction;
      } => ({
        action: {
          type: CrudActionType.CLONE,
          id: route.params.id as string,
        },
      }),
      meta: {
        title: 'Clone rotation',
      },
    },
  ];
}
