<template>
  <RouterView />

  <RouterNavigationProgress />
</template>

<script lang="ts" setup>
  import applyAuthSecretTokenToLocation from '../features/authentication/applyAuthSecretTokenToLocation';
  import useAuthenticationAndLicenseGuard from '../features/authentication/authentication-guard/useAuthenticationAndLicenseGuard';
  import loadCurrentUserRoleOnMount from '../features/authentication/current-user/loadCurrentUserRoleOnMount';
  import useAuthSecretToken from '../features/authentication/useAuthSecretToken';
  import initializeColorTheme from '../features/color-theme/initializeColorTheme';
  import onSystemColorThemeChanged from '../features/color-theme/onSystemColorThemeChanged';
  import applyDebugCheckerBasedOnMonitorDebugSettingsToDefaultLogger from '../features/debug/applyDebugCheckerBasedOnMonitorDebugSettingsToDefaultLogger';
  import logRouterNavigation from '../features/debug/logRouterNavigation';
  import RouterNavigationProgress from '../features/router-navigation-progress/RouterNavigationProgress.vue';
  import useUpdatePageRouterGuard from '../features/version-update/update-page/useUpdatePageRouterGuard';

  applyDebugCheckerBasedOnMonitorDebugSettingsToDefaultLogger();

  loadCurrentUserRoleOnMount();

  useAuthenticationAndLicenseGuard();

  logRouterNavigation();

  useUpdatePageRouterGuard();

  initializeColorTheme();

  onSystemColorThemeChanged(() => {
    initializeColorTheme();
  });

  const { authSecretToken } = useAuthSecretToken();

  applyAuthSecretTokenToLocation(authSecretToken);
</script>

<style lang="scss">
  @import '../scss/global';
</style>
