import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import style from './toastNotificationStyles.module.scss';

export default function createToast() {
  return [
    Toast,
    {
      transition: 'Vue-Toastification__fade',
      containerClassName: style.container,
      maxToasts: 3,
    },
  ] as const;
}
