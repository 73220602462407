const DEFAULT_RETURN_PATH = '/';

export default function getReturnPathForAuthentication(fullRoutePath: string) {
  if (fullRoutePath === DEFAULT_RETURN_PATH) {
    return undefined;
  }

  const searchStringMatchingResult = fullRoutePath.match(/\?(.+?)$/);

  if (!searchStringMatchingResult || !searchStringMatchingResult[1]) {
    return fullRoutePath;
  }

  const searchParams = new URLSearchParams(searchStringMatchingResult[1]);

  return searchParams.get('returnTo') ?? fullRoutePath;
}
