import MayBePromise from '../../../lib/types/MayBePromise';

type Resolver<U> = (data: U) => void;

export default function makeDeduplicatedFunction<T extends unknown[], U>(method: (...args: [...T]) => MayBePromise<U>) {
  let isCalled = false;
  let resolvers: Resolver<U>[] = [];

  return async (...args: [...T]) => {
    if (isCalled) {
      return new Promise<U>((resolve) => {
        resolvers.push(resolve);
      });
    }

    isCalled = true;

    const result = await method(...args);

    void new Promise(() => {
      const resolversCopy = [...resolvers];

      resolvers = [];
      isCalled = false;

      resolversCopy.forEach((resolver) => {
        resolver(result);
      });
    });

    return result;
  };
}
