import qs from 'qs';
import {
  RouteLocationNormalized,
  createRouter as createVueRouter,
  createWebHistory,
  parseQuery,
  stringifyQuery,
} from 'vue-router';

import getRoutes from './getRoutes';

const getRouteTitle = (route: RouteLocationNormalized): string => {
  if (typeof route.meta.title === 'string' && route.meta.title.trim() !== '') {
    return route.meta.title.trim();
  }
  return 'Binom';
};

export default function createRouter(routerPrefix: string) {
  const router = createVueRouter({
    history: createWebHistory(`/${routerPrefix}/`),
    parseQuery: qs.parse as typeof parseQuery,
    stringifyQuery: qs.stringify as typeof stringifyQuery,
    routes: getRoutes(),
  });

  router.beforeEach((to, from, next) => {
    document.title = getRouteTitle(to);
    next();
  });

  return router;
}
