import getApiClient from '../../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../../shared/api/api-result/ApiResultStatus';

export default async function checkForUpdates(): Promise<
  | {
      status: ApiResultStatus.ERROR;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.OK;
      isUpdateAvailable: boolean;
    }
> {
  const client = getApiClient();

  const response = await client.get('/updates/check');

  if (!response.ok) {
    return {
      status: ApiResultStatus.ERROR,
      errorMessage: await response.errorMessage(),
    };
  }

  const json = await response.json<{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_available_updates: boolean;
  }>();

  return {
    status: ApiResultStatus.OK,
    isUpdateAvailable: json.is_available_updates,
  };
}
