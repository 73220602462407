import { Ref, watch } from 'vue';

import onLocationChangedByHistoryApi from '../../lib/history-api/onLocationChangedByHistoryApi';
import writeValueToRouterWithoutReactivity from '../../lib/routing/writeValueToRouterWithoutReactivity';

export default function applyAuthSecretTokenToLocation(authSecretToken: Readonly<Ref<string | null>>) {
  const writeAuthSecretTokenToRouter = writeValueToRouterWithoutReactivity({
    writeValueToRouteQuery: (routeQuery, nextAuthSecretToken) => ({
      ...routeQuery,
      auth: nextAuthSecretToken,
    }),
  });

  watch(
    authSecretToken,
    () => {
      if (!authSecretToken.value) {
        return;
      }
      writeAuthSecretTokenToRouter(authSecretToken.value);
    },
    {
      immediate: true,
    },
  );

  onLocationChangedByHistoryApi(() => {
    if (!authSecretToken.value) {
      return;
    }
    writeAuthSecretTokenToRouter(authSecretToken.value);
  });
}
