import { RouteRecordRaw } from 'vue-router';

import RouteName from '../../router/RouteName';

const FilesPage = () => import('../../pages/files/FilesPage.vue');

export default function getFilesRoutes(): RouteRecordRaw[] {
  return [
    {
      name: RouteName.FILES,
      path: '/files',
      meta: {
        title: 'Files',
        useIn: ['navigation-tabs'],
      },
      component: FilesPage,
    },
  ];
}
