import { without } from 'lodash-es';
import { defineStore } from 'pinia';
import { onBeforeUnmount } from 'vue';

declare const window: Window & {
  history: History & {
    pushState: History['pushState'] & {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __isWrapped?: boolean;
    };
    replaceState: History['pushState'] & {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __isWrapped?: boolean;
    };
  };
};

/* eslint-disable @typescript-eslint/unbound-method */
const originalPushState = window.history.pushState;
const originalReplaceState = window.history.replaceState;
/* eslint-enable @typescript-eslint/unbound-method */

const useLocationChangedHandlersStore = defineStore('locationChangedHandlers', {
  state: (): {
    handlers: (() => void)[];
  } => ({
    handlers: [],
  }),
  actions: {
    callAllHandlers() {
      this.handlers.forEach((handler) => {
        handler();
      });
    },
    /**
     * @description расширяет оригинальный pushState метод History API вызовом всех хендлеров из списка
     */
    wrapPushStateMethod() {
      // чтобы не расширять уже расширенный метод, проверяем флаг.
      // у расширенного метода он будет, у оригинального - нет
      if (window.history.pushState.__isWrapped) {
        return;
      }

      const callAllHandlers = this.callAllHandlers.bind(this);

      window.history.pushState = function (...args: Parameters<typeof originalPushState>) {
        originalPushState.call(this, ...args);

        callAllHandlers();
      };

      window.history.pushState.__isWrapped = true;
    },
    /**
     * @description расширяет оригинальный replaceState метод History API вызовом всех хендлеров из списка
     */
    wrapReplaceStateMethod() {
      if (window.history.replaceState.__isWrapped) {
        return;
      }

      const callAllHandlers = this.callAllHandlers.bind(this);

      window.history.replaceState = function (...args: Parameters<typeof originalReplaceState>) {
        originalReplaceState.call(this, ...args);

        callAllHandlers();
      };

      window.history.replaceState.__isWrapped = true;
    },
    /**
     * @description расширяет оригинальные pushState и replaceState методы History API вызовом всех хендлеров из списка
     */
    wrapHistoryApiMethods() {
      this.wrapPushStateMethod();
      this.wrapReplaceStateMethod();
    },
    /**
     * @description деактивирует расширение с вызовом хендлеров
     */
    unwrapHistoryApiMethods() {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    },
    /**
     * @description когда нет хендлеров, нет необходимости в расширении для вызова хендлеров,
     *              поэтому в pushState и replaceState возвращаются оригинальные методы.
     *              когда хендлеры есть, методы нужно расширить
     *
     */
    wrapOrUnwrapHistoryApiMethods() {
      if (this.handlers.length === 0) {
        this.unwrapHistoryApiMethods();
        return;
      }
      this.wrapHistoryApiMethods();
    },
    addHandler(handler: () => void) {
      this.handlers.push(handler);

      this.wrapOrUnwrapHistoryApiMethods();
    },
    removeHandler(handler: () => void) {
      this.handlers = without(this.handlers, handler);

      this.wrapOrUnwrapHistoryApiMethods();
    },
  },
});

export default function onLocationChangedByHistoryApi(callback: () => void) {
  const locationChangedHandlersStore = useLocationChangedHandlersStore();

  locationChangedHandlersStore.addHandler(callback);

  onBeforeUnmount(() => {
    locationChangedHandlersStore.removeHandler(callback);
  });
}
