import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';

export default async function getIsHideProfit(userId: string): Promise<
  | {
      status: ApiResultStatus.ERROR;
      errorMessage: string;
    }
  | {
      status: ApiResultStatus.OK;
      isHideProfit: boolean;
    }
> {
  const client = getApiClient();

  const response = await client.get(`/api/user/${userId}/hide_profit`);

  if (!response.ok) {
    return {
      status: ApiResultStatus.ERROR,
      errorMessage: await response.errorMessage(),
    };
  }

  const json = await response.json<{
    hideProfit: boolean;
  }>();

  return {
    status: ApiResultStatus.OK,
    isHideProfit: json.hideProfit,
  };
}
