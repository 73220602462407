import { RouteRecordRaw } from 'vue-router';

import getAffiliateNetworksRoutes from '../features/affiliate-networks/getAffiliateNetworksRoutes';
import getAuthenticationRoutes from '../features/authentication/getAuthenticationRoutes';
import getCampaignsRoutes from '../features/campaigns/getCampaignsRoutes';
import getFilesRoutes from '../features/files/getFilesRoutes';
import getLandingsRoutes from '../features/landings/getLandingsRoutes';
import getMonitorRoutes from '../features/monitor/getMonitorRoutes';
import getOffersRoutes from '../features/offers/getOffersRoutes';
import getReportRoutes from '../features/report/report-page/getReportRoutes';
import getRotationsRoutes from '../features/rotations/getRotationsRoutes';
import getSettingsRoutes from '../features/settings/getSettingsRoutes';
import getTrafficSourcesRoutes from '../features/traffic-sources/getTrafficSourcesRoutes';
import getTrendsRoutes from '../features/trends/getTrendsRoutes';
import getUsersRoutes from '../features/users/getUsersRoutes';

const StubPage = () => import('../pages/StubPage.vue');
const PageNotFound = () => import('../pages/PageNotFound.vue');
const GroupsPage = () => import('../pages/GroupsPage.vue');
const DomainsPage = () => import('../pages/DomainsPage.vue');
const ClicklogPage = () => import('../pages/ClicklogPage.vue');
const ConversionsPage = () => import('../pages/ConversionsPage.vue');
const UpdatePage = () => import('../pages/update/UpdatePage.vue');

export default function getRoutes(): RouteRecordRaw[] {
  return [
    {
      name: '404',
      path: '/404',
      meta: {
        title: '404 Not found',
      },
      component: PageNotFound,
    },
    ...getCampaignsRoutes(),
    ...getRotationsRoutes(),
    ...getReportRoutes(),
    ...getAuthenticationRoutes(),
    ...getUsersRoutes(),
    ...getMonitorRoutes(),
    ...getAffiliateNetworksRoutes(),
    ...getOffersRoutes(),
    ...getLandingsRoutes(),
    ...getTrafficSourcesRoutes(),
    ...getTrendsRoutes(),
    ...getFilesRoutes(),
    ...getSettingsRoutes(),
    {
      name: 'conversions',
      path: '/conversions',
      meta: {
        title: 'Conversions',
        useIn: ['navigation-tabs'],
      },
      component: ConversionsPage,
    },
    {
      name: 'clicklog',
      path: '/clicklog',
      meta: {
        title: 'Clicklog',
        useIn: ['navigation-tabs'],
      },
      component: ClicklogPage,
    },
    {
      name: 'triggers',
      path: '/triggers',
      meta: {
        title: 'Triggers',
        useIn: ['navigation-tabs'],
      },
      component: StubPage,
    },
    {
      name: 'update',
      path: '/update',
      meta: {
        title: 'Update',
        useIn: ['menu'],
      },
      component: StubPage,
    },
    {
      name: 'domains',
      path: '/domains',
      meta: {
        title: 'Domains',
        useIn: ['menu'],
      },
      component: DomainsPage,
    },
    {
      name: 'groups',
      path: '/groups',
      meta: {
        title: 'Groups',
        useIn: ['menu'],
      },
      component: GroupsPage,
    },
    {
      name: 'update',
      path: '/update',
      meta: {
        title: 'Update',
        useIn: ['menu'],
      },
      component: UpdatePage,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ];
}
